import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';

import { play, exit } from './timelines'

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';

export const renderRoutes = (routes = []) => {
    return (
    <Suspense fallback={<Loader />}>
          <Switch>
              {routes.map((route, i) => {
                  const Guard = route.guard || Fragment;
                  const Layout = route.layout || Fragment;
                  const Component = route.component;

                  return (
                      <Route
                          key={i}
                          path={route.path}
                          exact={route.exact}
                          render={(props) => (
                              <Guard>
                                  <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                              </Guard>
                          )}
                      />
                  );
              })}
          </Switch>
    </Suspense>
)};

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        component: lazy(() => import('./views/auth/signin/SignIn1'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signup',
        component: lazy(() => import('./views/auth/signup/SignUp1'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/forgot',
        component: lazy(() => import('./views/auth/forgot/Forgot'))
    },
    {
        exact: true,
        path: '/auth/signin-2',
        component: lazy(() => import('./views/auth/signin/SignIn2'))
    },
    {
        exact: true,
        path: '/auth/signup-2',
        component: lazy(() => import('./views/auth/signup/SignUp2'))
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/app/dashboard/default',
                component: lazy(() => import('./views/dashboard/DashDefault'))
            },
            {
                exact: true,
                path: '/create/loan',
                component: lazy(() => import('./views/loanpool/loans/QuickLoan/QuickLoan'))
            },
            {
                exact: true,
                path: '/create/success',
                component: lazy(() => import('./views/loanpool/loans/QuickLoan/QuickLoanDesktopSuccess'))
            },
            {
                exact: true,
                path: '/my/pending/quick/loan/',
                component: lazy(() => import('./views/loanpool/loans/QuickLoan/PendingQuickLoanDetail'))
            },
            {
                exact: true,
                path: '/my/active/quick/loan/',
                component: lazy(() => import('./views/loanpool/loans/QuickLoan/ActiveQuickLoanDetail'))
            },
            {
                exact: true,
                path: '/find/loans',
                component: lazy(() => import('./views/loanpool/FindLoan'))
            },
            {
                exact: true,
                path: '/my/offers',
                component: lazy(() => import('./views/loanpool/ActiveOfferOverview'))
            },
            {
                exact: true,
                path: '/my/offers/success',
                component: lazy(() => import('./views/loanpool/AcceptedOfferOverview'))
            },
            {
                exact: true,
                path: '/fund/detail/:id',
                component: lazy(() => import('./views/loanpool/FundDetail'))
            },
            {
                exact: true,
                path: '/my/loans/pending',
                component: lazy(() => import('./views/loanpool/PendingLoanOverview'))
            },
            {
                exact: true,
                path: '/my/loans',
                component: lazy(() => import('./views/loanpool/ActiveLoanOverview'))
            },
            {
                exact: true,
                path: '/my/loan/:id',
                component: lazy(() => import('./views/loanpool/ActiveLoanDetail'))
            },
            {
                exact: true,
                path: '/my/loan/pending/:id',
                component: lazy(() => import('./views/loanpool/PendingLoanDetail'))
            },
            {
                exact: true,
                path: '/my/loans/complete',
                component: lazy(() => import('./views/loanpool/loans/CompletedLoanOverview'))
            },
            {
                exact: true,
                path: '/profile',
                component: lazy(() => import('./views/profile/profile'))
            },
            {
                exact: true,
                path: '/lender-questionnaire',
                component: lazy(() => import('./views/firstTimeUser/firstTimeUser'))
            },
            {
                exact: true,
                path: '/help/general',
                component: lazy(() => import('./views/help/general'))
            },
            {
                exact: true,
                path: '/help/lender',
                component: lazy(() => import('./views/help/lender'))
            },
            {
                exact: true,
                path: '/lendinginfo/investing',
                component: lazy(() => import('./views/education/investing'))
            },
            {
                exact: true,
                path: '/lendinginfo/lending',
                component: lazy(() => import('./views/education/lending'))
            },
            {
                exact: true,
                path: '/support',
                component: lazy(() => import('./views/support/support'))
            },
            {
                exact: true,
                path: '/how',
                component: lazy(() => import('./views/resources/understanding'))
            },
            {
                path: '*',
                exact: true,
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;

/*

{
    exact: true,
    path: '/sample-page',
    component: lazy(() => import('./views/extra/SamplePage'))
},

            {
                exact: true,
                path: '/basic/button',
                component: lazy(() => import('./views/ui-elements/basic/BasicButton'))
            },
            {
                exact: true,
                path: '/basic/badges',
                component: lazy(() => import('./views/ui-elements/basic/BasicBadges'))
            },
            {
                exact: true,
                path: '/basic/breadcrumb',
                component: lazy(() => import('./views/ui-elements/basic/BasicBreadcrumb'))
            },
            {
                exact: true,
                path: '/basic/collapse',
                component: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
            },
            {
                exact: true,
                path: '/basic/tabs-pills',
                component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
            },
            {
                exact: true,
                path: '/basic/typography',
                component: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
            },

            {
                exact: true,
                path: '/forms/form-basic',
                component: lazy(() => import('./views/forms/FormsElements'))
            },
            {
                exact: true,
                path: '/tables/bootstrap',
                component: lazy(() => import('./views/tables/BootstrapTable'))
            },

            {
                exact: true,
                path: '/charts/nvd3',
                component: lazy(() => import('./views/charts/nvd3-chart'))
            },
            {
                exact: true,
                path: '/maps/google-map',
                component: lazy(() => import('./views/maps/GoogleMaps'))
            },
 */
