import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';

const NavLogo = () => {
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const { dispatch } = configContext;

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
      <React.Fragment>
          <div className="navbar-brand header-logo">
              <Link to="#" className="b-brand">

                      <img src="https://lend.easypayd.com/peer2peer.money-logo-vector.svg" style={{width: '35px', height: '35px'}} />

                  <span className="b-title">EasyPayd</span>
              </Link>
              <Link
                  to="#"
                  className={toggleClass.join(' ')}
                  id="mobile-collapse"
                  onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
              >
                  <span />
              </Link>
          </div>
      </React.Fragment>
    );
};

export default NavLogo;
