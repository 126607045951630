import React from 'react';
import { ListGroup, Dropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Jdenticon from 'react-jdenticon';
import { useDispatch, useSelector } from 'react-redux';

import useWindowSize from '../../../../hooks/useWindowSize';
import NavSearch from './NavSearch';

const NavLeft = () => {
    const windowSize = useWindowSize();

    let dropdownRightAlign = false;

    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    let navItemClass = ['nav-item'];
    if (windowSize.width <= 575) {
        navItemClass = [...navItemClass, 'd-none'];
    }

    return (
        <React.Fragment>
          <div style={{...{padding: '1em'},...{display: 'flex'},...{alignItems: 'center'}}}>
              <Jdenticon size="48" value={account.user.id} /> {account.user.first_name} {account.user.last_name}
          </div>
        </React.Fragment>
    );
};

export default NavLeft;
