const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Home',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/app/dashboard/default',
                    icon: 'feather icon-home'
                },
                {
                    id: 'understand',
                    title: 'How It Works',
                    type: 'item',
                    icon: 'feather icon-help-circle',
                    classes: 'nav-item',
                    url: '/how',
                    target: false,
                    external: false
                },
            ]
        },

        /*{
            id: 'ui-element',
            title: 'UI ELEMENT',
            type: 'group',
            icon: 'icon-ui',
            children: [
                {
                    id: 'basic',
                    title: 'Components',
                    type: 'collapse',
                    icon: 'feather icon-box',
                    children: [
                        {
                            id: 'button',
                            title: 'Button',
                            type: 'item',
                            url: '/basic/button'
                        },
                        {
                            id: 'badges',
                            title: 'Badges',
                            type: 'item',
                            url: '/basic/badges'
                        },
                        {
                            id: 'breadcrumb',
                            title: 'Breadcrumb',
                            type: 'item',
                            url: '/basic/breadcrumb'
                        },
                        {
                            id: 'collapse',
                            title: 'Collapse',
                            type: 'item',
                            url: '/basic/collapse'
                        },
                        {
                            id: 'tabs-pills',
                            title: 'Tabs & Pills',
                            type: 'item',
                            url: '/basic/tabs-pills'
                        },
                        {
                            id: 'typography',
                            title: 'Typography',
                            type: 'item',
                            url: '/basic/typography'
                        }
                    ]
                }
            ]
        },
        {
            id: 'ui-forms',
            title: 'Forms & Tables',
            type: 'group',
            icon: 'icon-group',
            children: [
                {
                    id: 'forms',
                    title: 'Forms',
                    type: 'item',
                    url: '/forms/form-basic',
                    icon: 'feather icon-file-text'
                },
                {
                    id: 'bootstrap',
                    title: 'Bootstrap Table',
                    type: 'item',
                    url: '/tables/bootstrap',
                    icon: 'feather icon-server'
                }
            ]
        },
        {
            id: 'chart-maps',
            title: 'Chart & Maps',
            type: 'group',
            icon: 'icon-charts',
            children: [
                {
                    id: 'charts',
                    title: 'Charts',
                    type: 'item',
                    url: '/charts/nvd3',
                    icon: 'feather icon-pie-chart'
                },
                {
                    id: 'maps',
                    title: 'Maps',
                    type: 'item',
                    url: '/maps/google-map',
                    icon: 'feather icon-map'
                }
            ]
        },*/
        {
            id: 'pages',
            title: 'Loans',
            type: 'group',
            icon: 'icon-pages',
            children: [
                /*{
                    id: 'auth',
                    title: 'Authentication',
                    type: 'collapse',
                    icon: 'feather icon-lock',
                    children: [
                        {
                            id: 'signup-2',
                            title: 'Sign UP (template)',
                            type: 'item',
                            url: '/auth/signup-2',
                            target: true,
                            breadcrumbs: false
                        },
                        {
                            id: 'signin-2',
                            title: 'Sign IN (template)',
                            type: 'item',
                            url: '/auth/signin-2',
                            target: true,
                            breadcrumbs: false
                        }
                    ]
                },*/
                {
                    id: 'understand',
                    title: 'Find Loans',
                    type: 'item',
                    icon: 'feather icon-plus',
                    classes: 'nav-item',
                    url: '/find/loans',
                    target: false,
                    external: false
                },
                {
                    id: 'quick-create',
                    title: 'Create Loan',
                    type: 'item',
                    icon: 'feather icon-plus',
                    classes: 'nav-item',
                    url: '/create/loan',
                    target: false,
                    external: false
                },
                {
                    id: 'fund-overview',
                    title: 'My Offers',
                    type: 'collapse',
                    icon: 'feather icon-edit',
                    children: [
                      {
                          id: 'active-offers',
                          title: 'Active Offers',
                          type: 'item',
                          url: '/my/offers',
                          icon: 'feather icon-activity',
                      },
                      {
                          id: 'accepted-offers',
                          title: 'Accepted Offers',
                          type: 'item',
                          url: '/my/offers/success',
                          icon: 'feather icon-check-square',
                      }
                    ]
                },
                {
                    id: 'loan-overview',
                    title: 'My Loans',
                    type: 'collapse',
                    icon: 'feather icon-sidebar',
                    children: [
                      {
                          id: 'pending-loans',
                          title: 'Pending Loans',
                          type: 'item',
                          url: '/my/loans/pending',
                          icon: 'feather icon-info',
                      },
                      {
                          id: 'active-loans',
                          title: 'Active Loans',
                          type: 'item',
                          url: '/my/loans',
                          icon: 'feather icon-activity',
                      },
                      {
                          id: 'completed-loans',
                          title: 'Completed Loans',
                          type: 'item',
                          url: '/my/loans/complete',
                          icon: 'feather icon-check-square',
                      }
                    ]
                }
            ]
        },
        {
            id: 'resources',
            title: 'Resources',
            type: 'group',
            icon: 'icon-pages',
            children: [
              {
                  id: 'profile',
                  title: 'My Profile',
                  type: 'item',
                  icon: 'feather icon-user',
                  classes: 'nav-item',
                  url: '/profile',
                  target: false,
                  external: false
              },
              {
                id: 'education',
                title: 'Lending Info',
                type: 'collapse',
                icon: 'feather icon-sidebar',
                children: [
                  {
                      id: 'investing',
                      title: 'Investing',
                      type: 'item',
                      icon: 'feather icon-help-circle',
                      classes: 'nav-item',
                      url: '/lendinginfo/investing',
                      target: false,
                      external: false
                  },
                  {
                      id: 'lending',
                      title: 'Lending',
                      type: 'item',
                      icon: 'feather icon-help-circle',
                      classes: 'nav-item',
                      url: '/lendinginfo/lending',
                      target: false,
                      external: false
                  },
                ]
              },
              {
                  id: 'product-page',
                  title: 'Get Help',
                  type: 'collapse',
                  icon: 'feather icon-help-circle',
                  children: [
                    {
                        id: 'general_help',
                        title: 'General',
                        type: 'item',
                        icon: 'feather icon-help-circle',
                        classes: 'nav-item',
                        url: '/help/general',
                        target: false,
                        external: false
                    },
                    {
                        id: 'lender_help',
                        title: 'Lender',
                        type: 'item',
                        icon: 'feather icon-help-circle',
                        classes: 'nav-item',
                        url: '/help/lender',
                        target: false,
                        external: false
                    },
                  ]
                },
                {
                    id: 'support',
                    title: 'Get Support',
                    type: 'item',
                    icon: 'feather icon-help-circle',
                    classes: 'nav-item',
                    url: '/support',
                    target: false,
                    external: false
                },
            ]
        }
    ]
};

export default menuItems;
