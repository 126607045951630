import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { HashRouter as Router } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';

import { useDispatch } from 'react-redux';

import axios from 'axios';

import { LOGOUT } from './store/actions';

import { createBrowserHistory } from "history";



const InverseColorButton = styled(Button)({
  color: '#53c0a5',
  backgroundColor: '#ffffff',
  border: '2px solid #53c0a5',
  '&:hover': {
    backgroundColor: '#53c0a5',
    color: '#ffffff'
  },
});


const App = () => {

  const history = createBrowserHistory();

  const UNAUTHORIZED = 401;
  const timeout = 1000 * 60 * 3;
  const dispatcher = useDispatch();

  const [isAuthenticated, setAuth] = useState(false);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(30);
  const account = useSelector((state) => state.account);

  const handleOnActive = () => {  };
  const handleOnIdle = () => { if ( account.isLoggedIn === true ) { setOpen(true) } };
  let timeoutInterval;
  const handleLogout = () => {
    dispatcher({ type: LOGOUT });
  }

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  const handleReset = () => { reset(); };
  const handlePause = () => pause();
  const handleResume = () => resume();

  const handleClose = () => {
    setOpen(false);
    clearInterval(timeoutInterval);
    timeoutInterval = null;
    setCount(30);
    reset();
  }

  axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if ( error.response ) {
      const { status } = error.response;
      const { response } = error;
      if (status === UNAUTHORIZED) {
        dispatcher({ type: LOGOUT });
      }
      return error.response;
    } else if ( error.request ) {
      return error.request;
    } else {
      return error.message;
    }
    });

    useEffect(() => {
      console.log(account);
      if ( account.isLoggedIn === true ) {
        if (open) {
          let countDown = 30;
          timeoutInterval = setInterval(() => {
            setCount(--countDown);
            if ( countDown === 0 ) {

              handleLogout();
              setOpen(false);
              clearInterval(timeoutInterval);

            }
          },1000);
        } else {
          clearInterval(timeoutInterval);
        }
      }
      return () => clearInterval(timeoutInterval);
    }, [open])
//
    return (
        <React.Fragment>
            <Router basename={BASENAME}>{renderRoutes(routes)}</Router>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth={'md'}
            >
              <DialogTitle id="alert-dialog-title" style={{border: '2px solid #53c0a5'}}>
                {"Are you there?"}
              </DialogTitle>
              <DialogContent style={{...{backgroundColor: '#fff'},...{borderLeft: '2px solid #53c0a5'},...{borderRight: '2px solid #53c0a5'}}}>
                <Typography>Your session will expire in {count.toString()} seconds.</Typography>
                <Typography>Click continue session if you don't want to be logged out.</Typography>
              </DialogContent>
              <DialogActions style={{border: '2px solid #53c0a5'}}>
                <InverseColorButton style={{width: '100%'}} onClick={() => { handleClose(); }}>Continue session ...</InverseColorButton>
              </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default App;
